<template>
  <div class="b-loading" v-if="isLoading">
    <div class="b-loading--overlay" />
    <div class="b-loading--spinner">
      <div></div>
      <div></div>
      <div></div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isLoading() {
      return this.$store.getters['loading/isLoading'];
    }
  }
};
</script>

<style lang="scss">
.b-loading {
  z-index: 9999;
  position: fixed;
  display: flex;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  align-items: center;
  justify-content: center;
  overflow: hidden;

  &--overlay {
    background: #bbbbbb;
    backdrop-filter: blur(2px);
    bottom: 0;
    left: 0;
    opacity: 0.7;
    position: absolute;
    right: 0;
    top: 0;
  }

  &--spinner {
    display: inline-block;
    position: relative;

    div {
      display: inline-block;
      position: absolute;
      left: 8px;
      width: 16px;
      background: var(--v-primary-base);
      animation: spinner 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;

      &:nth-child(1) {
        left: 8px;
        animation-delay: -0.24s;
      }

      &:nth-child(2) {
        left: 32px;
        animation-delay: -0.12s;
      }

      &:nth-child(3) {
        left: 56px;
        animation-delay: 0;
      }
    }
  }
}

@keyframes spinner {
  0% {
    top: 8px;
    height: 80px;
  }
  50%,
  100% {
    top: 24px;
    height: 40px;
  }
}
</style>
